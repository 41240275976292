// @ts-ignore
import * as Handlebars from 'handlebars/dist/handlebars';
import {
    AutocompleteComponent,
    CheckboxListFacetComponent,
    ColorFacetComponent,
    ContentZoneComponent,
    CustomContentComponent,
    DatePickerComponent,
    DateRangeFacetComponent,
    FacetWrapperComponent,
    FacetsListComponent,
    FeaturedItemsContentComponent,
    FeaturedItemsContentItemComponent,
    IconComponent,
    ImageContentComponent,
    LandingPageComponent,
    LinkListFacetComponent,
    ModifiedQueryComponent,
    NumericRangeFacetComponent,
    PageSizeComponent,
    PaginationComponent,
    PopularQueriesContentComponent,
    QuerySuggestionsComponent,
    RangeSliderComponent,
    RangeSliderFacetComponent,
    RatingComponent,
    RecentSearchesFacetComponent,
    RecommendationsComponent,
    RecommendationsItemComponent,
    RelatedSearchesFacetComponent,
    SearchFieldComponent,
    SearchResultsComponent,
    SearchResultsItemComponent,
    SearchResultsListComponent,
    SearchWithinFacetComponent,
    SelectedFacetsComponent,
    SizeFacetComponent,
    SortingComponent,
    TabsComponent,
    TooltipComponent
} from '@components';
import { HawkSearchComponents, HawkSearchGlobal, HawkSearchState } from '@configuration';
import { add, and, attribute, concat, currency, eq, exclude, gt, gte, html, ifElse, lt, lte, number, or, string, subtract } from '@helpers';
import { autocompleteService, recommendationsService, searchService, trackingService } from '@services';
import { BaseComponent } from './components/base.component';

declare let HawkSearch: HawkSearchGlobal;

if (!HawkSearch) {
    HawkSearch = {} as HawkSearchGlobal;
}

HawkSearch.handlebars = Handlebars.noConflict();

HawkSearch.handlebars.registerHelper('add', add);
HawkSearch.handlebars.registerHelper('and', and);
HawkSearch.handlebars.registerHelper('attribute', attribute);
HawkSearch.handlebars.registerHelper('concat', concat);
HawkSearch.handlebars.registerHelper('eq', eq);
HawkSearch.handlebars.registerHelper('exclude', exclude);
HawkSearch.handlebars.registerHelper('html', html);
HawkSearch.handlebars.registerHelper('currency', currency);
HawkSearch.handlebars.registerHelper('number', number);
HawkSearch.handlebars.registerHelper('if-else', ifElse);
HawkSearch.handlebars.registerHelper('lt', lt);
HawkSearch.handlebars.registerHelper('lte', lte);
HawkSearch.handlebars.registerHelper('gt', gt);
HawkSearch.handlebars.registerHelper('gte', gte);
HawkSearch.handlebars.registerHelper('or', or);
HawkSearch.handlebars.registerHelper('string', string);
HawkSearch.handlebars.registerHelper('subtract', subtract);

HawkSearch.services = {
    autocomplete: autocompleteService,
    recommendations: recommendationsService,
    search: searchService,
    tracking: trackingService
};

const components: Record<keyof HawkSearchComponents, typeof BaseComponent<any, any, any>> = {
    'autocomplete': AutocompleteComponent,
    'checkbox-list-facet': CheckboxListFacetComponent,
    'color-facet': ColorFacetComponent,
    'content-zone': ContentZoneComponent,
    'custom-content': CustomContentComponent,
    'date-picker': DatePickerComponent,
    'date-range-facet': DateRangeFacetComponent,
    'facet-wrapper': FacetWrapperComponent,
    'facets-list': FacetsListComponent,
    'featured-items-content': FeaturedItemsContentComponent,
    'featured-items-content-item': FeaturedItemsContentItemComponent,
    'icon': IconComponent,
    'image-content': ImageContentComponent,
    'landing-page': LandingPageComponent,
    'link-list-facet': LinkListFacetComponent,
    'modified-query': ModifiedQueryComponent,
    'numeric-range-facet': NumericRangeFacetComponent,
    'page-size': PageSizeComponent,
    'pagination': PaginationComponent,
    'popular-queries-content': PopularQueriesContentComponent,
    'query-suggestions': QuerySuggestionsComponent,
    'range-slider': RangeSliderComponent,
    'range-slider-facet': RangeSliderFacetComponent,
    'rating': RatingComponent,
    'recommendations': RecommendationsComponent,
    'recommendations-item': RecommendationsItemComponent,
    'recent-searches-facet': RecentSearchesFacetComponent,
    'related-searches-facet': RelatedSearchesFacetComponent,
    'search-field': SearchFieldComponent,
    'search-results': SearchResultsComponent,
    'search-results-item': SearchResultsItemComponent,
    'search-results-list': SearchResultsListComponent,
    'search-within-facet': SearchWithinFacetComponent,
    'selected-facets': SelectedFacetsComponent,
    'size-facet': SizeFacetComponent,
    'sorting': SortingComponent,
    'tabs': TabsComponent,
    'tooltip': TooltipComponent
};

Object.entries(components).forEach(([name, component]) => {
    customElements.define(`hawksearch-${name}`, component as any);
});

window.addEventListener('popstate', (event) => {
    const state: HawkSearchState = event.state;

    HawkSearch.searchRequest = state.searchRequest;
    HawkSearch.searchResponse = state.searchResponse;

    searchService.bindComponents(state.searchResponse);
});

window.dispatchEvent(new CustomEvent('hawksearch:initialized'));
