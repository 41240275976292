import { unescape } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { HawkSearchComponents, HawkSearchGlobal } from '@configuration';

declare let HawkSearch: HawkSearchGlobal;

export abstract class BaseService {
    protected abstract baseUrl: string;

    public queryStringParams = {
        disableSpellcheck: HawkSearch.config.search?.queryStringMappings?.disableSpellcheck || 'disableSpellcheck',
        page: HawkSearch.config.search?.queryStringMappings?.page || 'page',
        pageSize: HawkSearch.config.search?.queryStringMappings?.pageSize || 'pageSize',
        query: HawkSearch.config.search?.queryStringMappings?.query || 'query',
        searchWithin: HawkSearch.config.search?.queryStringMappings?.searchWithin || 'searchWithin',
        sort: HawkSearch.config.search?.queryStringMappings?.sort || 'sort'
    };

    public searchUrl = HawkSearch.config.search?.url ?? '/search';

    protected async httpPost<T>(relativeUrl: string, body?: any): Promise<T> {
        const url = this.baseUrl + relativeUrl;
        const headers = this.getHeaders();

        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        const contentType = response.headers.get('content-type');

        if (contentType?.startsWith('application/json')) {
            return response.json();
        } else {
            return undefined as unknown as T;
        }
    }

    protected stripHtml(input: string): string {
        let output = input.replace(/<[^>]+>/g, '');

        output = unescape(output);
        output = decodeURIComponent(output);

        return output;
    }

    private getHeaders(): Headers {
        const headers = new Headers();

        headers.set('Content-Type', 'application/json');
        headers.set('X-Requested-With', 'XMLHttpRequest');

        return headers;
    }

    protected getVisitorId(): string {
        const key = 'VisitorId';
        let value = localStorage.getItem(key);

        if (!value) {
            value = this.generateGuid();

            localStorage.setItem(key, value);
        }

        return value;
    }

    protected getVisitId(): string {
        const key = 'VisitId';
        let value = sessionStorage.getItem(key);

        if (!value) {
            value = this.generateGuid();

            sessionStorage.setItem(key, value);
        }

        return value;
    }

    protected generateGuid(): string {
        return uuid();
    }

    protected getUrl(prefix: string | undefined, url: string | undefined): string | undefined {
        if (!url) {
            return undefined;
        }

        if (!!prefix && !/^https?:\/\//.test(url)) {
            return `${prefix}/${url.replace(/^\/+/, '')}`;
        }

        return url;
    }

    protected triggerBindEvent<T>(component: keyof HawkSearchComponents, data: T, filter?: string): void {
        let eventName = `hawksearch:bind-${component}`;

        if (filter) {
            eventName = `${eventName}:${filter}`;
        }

        this.triggerEvent(eventName, data);
    }

    protected triggerEvent<T>(name: string, data: T): void {
        const event = new CustomEvent(name, { detail: data });

        window.dispatchEvent(event);
    }

    protected getString(values: Array<string> | undefined, defaultValue?: string): string | undefined {
        return values?.[0] ?? defaultValue;
    }

    protected getNumber(values: Array<string> | undefined, defaultValue?: number): number | undefined {
        const string = this.getString(values);
        let value = string ? parseFloat(string) : undefined;

        if (value !== undefined && isNaN(value)) {
            value = undefined;
        }

        return value ?? defaultValue;
    }
}
