import { BaseComponentConfig } from '@configuration';
import { BaseComponentModel, ContentType } from '@models';
import { BaseComponent } from '../../base.component';

/**
 * @category Content
 */
export abstract class BaseContentComponent<
    TConfig extends BaseComponentConfig,
    TData extends ContentType,
    TModel extends BaseComponentModel
> extends BaseComponent<TConfig, TData, TModel> {
    protected override bindFromEvent = false;
}
