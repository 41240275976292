import { HawkSearchComponents, RatingComponentConfig } from '@configuration';
import { IconName, RatingComponentModel } from '@models';
import { BaseComponent } from '../../base.component';
import defaultHtml from './rating.component.hbs';

/**
 * The Rating component is used to display a visual representation of ratings for a particular product.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-rating>`.
 *
 * ## Attributes
 * | Name | Type | Default Value | Required |
 * | :- | :- | :- | :- |
 * | rating | `number` | | Yes
 *
 * These attributes are used to position the tooltip based on the size and scroll position of the active window. The `hawksearch-tooltip` attribute should be present on an element containing both the element the tooltip is attached to (to display on hover) and the element containing the tooltip content, which should have a `hawksearch-tooltip-content` attribute.
 *
 * ## Handlebars Helpers
 * | Name | Rating | Star Number
 * | :- | :- | :- |
 * | ratingIcon | `number` | `number` |
 *
 * This helper function returns the icon name to display for a given rating and star number (1-5)
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./rating.component.hbs}
 *
 * @category General
 */
export class RatingComponent extends BaseComponent<RatingComponentConfig, number, RatingComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'rating';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    protected override registerHelpers(): void {
        super.registerHelpers();

        this.handlebars.registerHelper('ratingIcon', (rating: number, star: number): IconName => {
            const value = rating - star;

            if (value >= 1) {
                return 'star';
            } else if (value >= 0.5) {
                return 'star-half';
            } else {
                return 'star-empty';
            }
        });
    }

    private get rating(): number | undefined {
        return parseFloat(this.getAttribute('rating') ?? '0') || undefined;
    }

    protected override renderContent(): boolean {
        return !!this.rating;
    }

    protected override getContentModel(): RatingComponentModel {
        return {
            rating: this.rating!
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelector('[hawksearch-tooltip]')?.addEventListener('mouseover', (event: Event) => {
            const element = event.currentTarget as HTMLElement;
            const contentElement = this.rootElement.querySelector('[hawksearch-tooltip-content]') as HTMLElement;

            if (!contentElement) {
                return;
            }

            contentElement.style.removeProperty('top');
            contentElement.style.removeProperty('right');

            const windowWidth = window.innerWidth;
            const boundingRect = element.getBoundingClientRect();
            const rightPosition = boundingRect.x + boundingRect.width;
            const maxWidth = 350;
            const margin = 20;

            contentElement.style.top = `${boundingRect.top}px`;

            if (windowWidth - rightPosition < maxWidth + margin) {
                contentElement.style.right = `${margin}px`;
            }
        });
    }
}
